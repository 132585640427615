<template>
  <div class="invoices flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <date-range
          v-model="filters"
          @input="fetchInvoices()"
        />
        <div class="flex flex-column gap-1">
          <div>Type:</div>
          <button-select
            v-model="filters.invoice_type"
            :options="options.invoice_type"
            size="sm"
            @input="fetchInvoices()"
          />
        </div>

        <div class="flex flex-column gap-1 hidden">
          <div>Supplier:</div>
          <div></div>
        </div>

        <div class="flex flex-column gap-1">
          <div>Search:</div>
          <b-form-input
            v-model="filters.search"
            placeholder="Search in Invoice, Notes"
            size="sm"
            autocomplete="off"
            trim
            type="search"
            debounce="500"
            @change="fetchInvoices()"
            class="search-field"
          ></b-form-input>
        </div>

      </div>
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createInvoice()"
        >
          Add invoice
        </b-button>

        <b-button
          size="sm"
          variant="light"
          class="text-nowrap"
          @click="exportInvoices()"
        >
          Export
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ invoices.length | format }} / {{ invoiceCount | format }} invoice(s)
    </div>

    <table class="table table-google table-invoices">
      <thead>
        <tr>
          <th>ID</th>
          <th>Type</th>
          <th>Date</th>
          <th>Supplier</th>
          <th>Invoice</th>
          <th>Amount</th>
          <th>Files</th>
          <th>Notes</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="9">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="invoices.length === 0">No invoices found</span>
            </div>
          </td>
        </tr>

        <tr v-for="invoice in invoices" :key="invoice.id">
          <td class="row-fit">{{ invoice.id }}</td>
          <td class="row-fit">
            <b-badge
              :variant="invoiceTypeBadge[invoice.invoice_type]"
            >
              {{ invoice.invoice_type | capitalize }}
            </b-badge>
          </td>
          <td class="row-fit">{{ invoice.issue_date | isodate }}</td>
          <td class="row-fit text-nowrap">
            {{ invoice.partner_text }}
          </td>
          <td class="row-fit text-nowrap">{{ invoice.invoice_code }}</td>
          <td class="row-fit text-right">
            <span class="text-nowrap">
              {{ invoice.price_brutto | format }} {{ invoice.currency_code }}
            </span>
          </td>
          <td class="row-fit">
            <div class="flex flex-column gap-2">
              <template v-for="file in invoice.files">
                <div
                  :key="`invoice_${invoice.id}_file_${file.id}`"
                  class="flex gap-2 items-center"
                >
                  <i class="fas fa-paperclip"></i>
                  <b-link
                    :href="file.link"
                    target="_blank"
                    class="custom-link text-nowrap"
                  >
                    {{ file.filename }}
                  </b-link>
                </div>
              </template>
            </div>
          </td>
          <td>{{ invoice.invoice_note }}</td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editInvoice(invoice)"
              >
                Edit
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="invoiceCount"
      :per-page="filters.per_page"
      @input="fetchInvoices(true)"
      :disabled="isLoading"
    ></b-pagination>

    <invoice-modal
      v-if="invoiceModal.showModal"
      :data="invoiceModal"
      :suppliers="options.supplier"
      @change="onInvoiceChange"
      @delete="onInvoiceDelete"
    />
  </div>
</template>

<script>
import { downloadFile } from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const DateRange = () => import('@/components/invoices/DateRange.vue');
const InvoiceModal = () => import('@/components/invoices/InvoiceModal.vue');

export default {
  name: 'InvoiceList',
  components: {
    ButtonSelect,
    DateRange,
    InvoiceModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      invoices: [],
      invoiceCount: 0,
      filters: {
        date_from: null,
        date_to: null,
        invoice_type: null,
        page: 1,
        per_page: 50,
        search: '',
      },
      options: {
        invoice_type: [
          { text: 'All', value: null },
          { text: 'Invoice', value: 'invoice' },
          { text: 'Proforma', value: 'proforma' },
        ],
      },
      invoiceTypeBadge: {
        invoice: 'primary',
        proforma: 'light',
      },
      invoiceModal: {
        showModal: false,
        invoice: null,
      },
      partnerNameFields: ['partner_name', 'partner_subname'],
      partnerAddressFields: ['address_street', 'address_city', 'address_zip', 'country_name'],
    };
  },
  methods: {
    fetchSuppliers() {
      this.loadingCount++;
      this.$http
        .get('/partner')
        .then((res) => {
          this.options.supplier = res.body.records.map((record) => {
            const partnerNameData = [];
            this.partnerNameFields.forEach((field) => {
              if (record[field] && record[field].length > 0) {
                partnerNameData.push(record[field]);
              }
            });
            if (partnerNameData.length === 0) {
              partnerNameData.push('Unknown partner');
            }
            const partnerName = partnerNameData.join(' ');

            const partnerAddressData = [];
            this.partnerAddressFields.forEach((field) => {
              if (record[field] && record[field].length > 0) {
                partnerAddressData.push(record[field]);
              }
            });
            if (partnerAddressData.length === 0) {
              partnerAddressData.push('Unknown address');
            }
            const partnerAddress = partnerAddressData.join(', ');
            return {
              text: `${partnerName} (${partnerAddress})`,
              value: record.id,
            };
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch suppliers: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchInvoiceData() {
      this.loadingCount++;
      this.$http
        .get('/invoice')
        .query(this.filters)
        .then((res) => {
          this.invoices = res.body.invoices;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch invoices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchInvoiceCount() {
      this.loadingCount++;
      this.$http
        .get('/invoice_count')
        .query(this.filters)
        .then((res) => {
          this.invoiceCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch invoice count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchInvoices(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchInvoiceCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchInvoiceData();
    },
    exportInvoices() {
      this.loadingCount++;
      this.$http
        .get('/invoice_export')
        .query(this.filters)
        .then((res) => {
          downloadFile(res.body.link);
        })
        .catch((err) => {
          this.$toast.error(`Failed to export invoices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createInvoice() {
      this.loadingCount++;
      this.$http
        .post('/invoice')
        .query(this.filters)
        .then((res) => {
          this.invoices.push(res.body.invoice);
          this.fetchInvoiceCount();
          this.editInvoice(res.body.invoice);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editInvoice(invoice) {
      this.invoiceModal.invoice = invoice;
      this.invoiceModal.showModal = true;
    },
    onInvoiceChange(invoiceId) {
      const invoiceIndex = this.invoices.findIndex((invoice) => invoice.id === invoiceId);
      if (invoiceIndex === -1) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/invoice/${invoiceId}`)
        .then((res) => {
          this.invoices[invoiceIndex] = res.body.invoice;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onInvoiceDelete(invoiceId) {
      this.invoices = this.invoices.filter((invoice) => invoice.id !== invoiceId);
      this.fetchInvoiceCount();
    },
  },
  created() {
    this.fetchSuppliers();
  },
};
</script>

<style lang="scss" scoped>
  .invoices {
    max-width: 1450px;
  }

  .table-invoices .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }

  .search-field {
    width: 250px;
  }
</style>
